<template>
  <div>
    <div class="row text-left px-5 mt-5">
      <div class="col-sm-3">
        <h6 class="mb-0">Packages Code</h6>
      </div>
      <div class="col-sm-9 f-bold cl-primary">
        {{ listDetailPackage.code }}
      </div>
    </div>
    <hr />

    <div class="row text-left px-5">
      <div class="col-sm-3">
        <h6 class="mb-0">Packages Description</h6>
      </div>
      <div class="col-sm-9 text-secondary" v-if="package_detail">
        {{ package_detail.description }}
      </div>
    </div>
    <hr />

    <div class="row text-left px-5">
      <div class="col-sm-3">
        <h6 class="mb-0">Shipping Address</h6>
      </div>
      <div class="col-sm-9 text-secondary" v-if="package_detail">
        {{ package_detail.shipping_address }}
      </div>
    </div>
    <hr />

    <div class="row text-left px-5">
      <div class="col-sm-3">
        <h6 class="mb-0">Approval Status</h6>
      </div>
      <div class="col-sm-9 text-secondary">
        <span
          class="badge badge-success p-2"
          v-if="listDetailPackage.status_approval == 'approved'"
          >Approved</span
        >
        <span
          class="badge badge-primary p-2"
          v-if="listDetailPackage.status_approval == 'waiting'"
          >Waiting</span
        >
        <span
          class="badge badge-warning p-2"
          v-if="listDetailPackage.status_approval == 'pending'"
          >Pending</span
        >
        <span
          class="badge badge-danger p-2"
          v-if="listDetailPackage.status_approval == 'rejected'"
          >Reject</span
        >
      </div>
    </div>
    <hr />

    <div class="row text-left px-5">
      <div class="col-sm-3">
        <h6 class="mb-0">Payment Status</h6>
      </div>
      <div class="col-sm-9 text-secondary">
        <span
          class="badge badge-success p-2"
          v-if="listDetailPackage.status_payment == 'paid'"
          >Approved Full Payment</span
        >
        <span
          class="badge badge-secondary p-2"
          v-if="listDetailPackage.status_payment == 'down payment 50%'"
          >Down Payment</span
        >
        <span
          class="badge badge-primary p-2"
          v-if="listDetailPackage.status_payment == 'waiting'"
          >Waiting</span
        >
        <span
          class="badge badge-warning p-2"
          v-if="listDetailPackage.status_payment == 'pending'"
          >Pending</span
        >
        <span
          class="badge badge-danger p-2"
          v-if="listDetailPackage.status_payment == 'rejected'"
          >Rejected</span
        >
      </div>
    </div>
    <hr />

    <div v-if="listDetailPackage.status_checking != 'waiting'">
      <div
        class="row text-left px-5"
        v-if="listDetailPackage.status_checking != 'waiting'"
      >
        <div class="col-sm-3">
          <h6 class="mb-0">Checking Status</h6>
        </div>
        <div class="col-sm-9 text-secondary">
          <span
            class="badge badge-success p-2"
            v-if="listDetailPackage.status_checking == 'match'"
            >Match</span
          >
          <span
            class="badge badge-danger p-2"
            v-if="listDetailPackage.status_checking == 'not-match'"
            >Not Match</span
          >
        </div>
      </div>
      <hr />
    </div>

    <div v-if="listDetailPackage.promotion_detail">
      <div class="row text-left px-5">
        <div class="col-sm-3">
          <h6 class="mb-0">Promotion</h6>
        </div>
        <div class="col-sm-9 text-secondary">
          <p>
            {{
              listDetailPackage.promotion_detail.code
                ? listDetailPackage.promotion_detail.code +
                  " Discount " +
                  listDetailPackage.promotion_detail.discount +
                  " IDR"
                : " - "
            }}
          </p>
        </div>
      </div>
      <hr />
    </div>

    <div
      v-if="
        listDetailPackage.is_jakarta == 0 &&
        listDetailPackage.status_payment === 'waiting'
      "
    >
      <div class="row text-left px-5">
        <div class="col-sm-3">
          <h6 class="mb-0">Additional Payment</h6>
        </div>
        <div class="col-sm-9 text-secondary">
          <form class="" @submit.prevent="submitAdditionalPayment">
            <div class="row">
              <div class="col-12 col-md-12">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control b-radius px-4"
                    id="total"
                    aria-describedby="start_dateHelp"
                    placeholder="Additional Payment"
                    v-model="form.total"
                    required
                  />
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-end">
              <button type="submit" class="btn btn-primary px-5">
                Send To Customer
              </button>
            </div>
          </form>
        </div>
      </div>
      <hr />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
export default {
  props: ["listDetailPackage", "package_detail", "getDetailPackage"],
  name: "CustomerProfileDetailPackage",
  data() {
    return {
      loading: false,
      form: {
        package_id: this.listDetailPackage.id,
        total: "",
      },
    };
  },

  mounted() {
    console.log("package_id" + this.listDetailPackage.id);
    console.log(this.package_detail);
  },

  methods: {
    submitAdditionalPayment() {
      Vue.swal({
        title: "Are you sure?",
        icon: "question",
        showCancelButton: true,
        cancelButtonColor: "#eb4d4b",
        confirmButtonText: "Yes, Submit!",
      }).then((result) => {
        if (result.isConfirmed) {
          const endpoint = "shipping-fee/package-invoice";
          this.$api.post(endpoint, this.form, (status, data, message) => {
            if (status === 200) {
              this.loading = false;
              console.log(data);
              Vue.swal("Success", "Invoice successfully added", "success").then(
                () => {
                  this.getDetailPackage();
                  // this.getInvoices(this.id);
                  this.resetForm();
                  this.scrollToEnd();
                }
              );
            } else {
              this.loading = false;
              console.log(message);
            }
          });
        }
      });
    },
  },
};
</script>
