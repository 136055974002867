<template>
  <div>
    <div class="row text-left px-5 mt-5">
      <div class="col-sm-3">
        <h6 class="mb-0">Full Name</h6>
      </div>
      <div class="col-sm-9 text-secondary" v-if="customer_detail">
        {{ customer_detail.name }}
      </div>
    </div>
    <hr />
    <div class="row text-left px-5">
      <div class="col-sm-3">
        <h6 class="mb-0">Email</h6>
      </div>
      <div class="col-sm-9 text-secondary" v-if="customer_detail">
        {{ customer_detail.email }}
      </div>
    </div>
    <hr />
    <div class="row text-left px-5">
      <div class="col-sm-3">
        <h6 class="mb-0">Phone</h6>
      </div>
      <div class="col-sm-9 text-secondary" v-if="customer_detail">
        {{ customer_detail.telephone }}
      </div>
    </div>
    <hr />
    <div class="row text-left px-5">
      <div class="col-sm-3">
        <h6 class="mb-0">Username</h6>
      </div>
      <div class="col-sm-9 text-secondary" v-if="customer_detail">
        {{ customer_detail.username }}
      </div>
    </div>
    <hr />
    <div class="row text-left px-5">
      <div class="col-sm-3">
        <h6 class="mb-0">Address</h6>
      </div>
      <div class="col-sm-9 text-secondary" v-if="customer_detail">
        {{ customer_detail.address }}
      </div>
    </div>
    <hr />
  </div>
</template>

<script>
export default {
  props: ["customer_detail"],
  name: "CustomerProfileDetailPackage",

  mounted() {
    console.log(this.customer_detail);
  },
};
</script>
